// src/redux/slices/sampleSlice.ts
import { createSlice } from '@reduxjs/toolkit';

interface SampleState {
  isSomething: boolean;
}

const initialState: SampleState = {
  isSomething: false,
};

const sampleSlice = createSlice({
  name: 'sample',
  initialState,
  reducers: {
    foo: (state) => {
      state.isSomething = true;
    },
    bar: (state) => {
      state.isSomething = false;
    },
  },
});

export const { foo, bar } = sampleSlice.actions;
export default sampleSlice.reducer;
