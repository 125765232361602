import { Navigate, Outlet } from 'react-router-dom';
import { AuthGuardProps } from 'src/types';

const AuthGuard: React.FC<AuthGuardProps> = ({ authRequired, roles }) => {
  const isAuthenticated = Boolean(localStorage.getItem('token')); // Replace with real auth logic
  const userRole = localStorage.getItem('role'); // Replace with real role-fetching logic

  if (authRequired && !isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (roles && !roles.includes(userRole || '')) {
    return <Navigate to="/403" replace />;
  }

  return <Outlet />;
};

export default AuthGuard;
