import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AppRouter } from './routes';

function App() {
  return (
    <>
      <Router>
        <AppRouter />
      </Router>
      <Toaster position='bottom-center' />
    </>
  );
}

export default App;
