import { lazy } from 'react';
import { RouteConfig } from '../types/router';
import { BASE_ROUTE, DASHBOARD, NOT_FOUND } from '@consts/paths';

const Login = lazy(() => import('../pages/Login'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const NotFound = lazy(() => import('../pages/NotFound'));

const routes: RouteConfig[] = [
  {
    path: BASE_ROUTE,
    element: Login,
  },
  {
    path: DASHBOARD,
    element: Dashboard,
  },
  {
    path: NOT_FOUND,
    element: NotFound,
  },
];

export default routes;
