// src/redux/store.ts
import { configureStore } from '@reduxjs/toolkit';
import sampleReducer from '../slices/sampleSlice';
import { logger } from 'redux-logger';

const store = configureStore({
  reducer: {
    sample: sampleReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


export default store;
