import { Routes, Route } from 'react-router-dom';
import { Suspense } from 'react';
import routes from './routes';
import { AuthGuard } from '@components/common/AuthGuard';

const AppRouter = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {routes.map((route) =>
          route.authRequired || route.roles ? (
            <Route
              key={route.path}
              path={route.path}
              element={<AuthGuard authRequired={route.authRequired} roles={route.roles} />}
            >
              <Route path={route.path} element={<route.element />} />
            </Route>
          ) : (
            // Public routes
            <Route key={route.path} path={route.path} element={<route.element />} />
          ),
        )}
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
